// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconInfo(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Info";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("g", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsxs("g", {
                                      children: [
                                        JsxRuntime.jsx("path", {
                                              d: "M8,16c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C16,12.4,12.4,16,8,16z M8,1.1\n					C4.2,1.1,1.1,4.2,1.1,8s3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9S11.8,1.1,8,1.1z",
                                              fill: Icon.mapColor(color)
                                            }),
                                        JsxRuntime.jsx("circle", {
                                              cx: "8",
                                              cy: "11.6",
                                              r: "0.9",
                                              stroke: Icon.mapColor(color)
                                            }),
                                        JsxRuntime.jsx("path", {
                                              d: "M6.8,5.3c0-1.2,0.4-1.8,1.2-1.8s1.2,0.6,1.2,1.8S8.8,8,8,9.8C7.2,8,6.8,6.5,6.8,5.3z",
                                              fill: Icon.mapColor(color)
                                            })
                                      ],
                                      transform: "translate(932.000000, 32.000000)"
                                    }),
                                transform: "translate(0.000000, 1576.000000)"
                              }),
                          transform: "translate(-932.000000, -1608.000000)"
                        })
                  })
            });
}

var make = IconInfo;

export {
  make ,
}
/* Icon Not a pure module */
