// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as IconInfo from "../../../styleguide/icons/IconInfo.res.js";
import * as TopNavbar from "../top-navbar/TopNavbar.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_User from "../../../routes/common/Routes_User.res.js";
import * as ConfirmModal from "./confirm-modal/ConfirmModal.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserInvitationData from "../../../models/UserInvitationData.res.js";
import * as UserInvitationsScss from "./UserInvitations.scss";

var css = UserInvitationsScss;

function UserInvitations$UserInvitations(props) {
  var mobile = props.mobile;
  var match = React.useReducer((function (state, action) {
          if (typeof action !== "object") {
            return {
                    isShowMoreExpanded: state.isShowMoreExpanded,
                    invitations: state.invitations,
                    totalPages: state.totalPages,
                    totalInvitations: state.totalInvitations,
                    offset: state.offset,
                    currentPage: state.currentPage,
                    fetching: state.fetching,
                    openConfirm: undefined,
                    currentInvitationId: undefined,
                    serverError: state.serverError
                  };
          }
          switch (action.TAG) {
            case "UpdateInvitations" :
                var res = action._0;
                return {
                        isShowMoreExpanded: state.isShowMoreExpanded,
                        invitations: res.invitations,
                        totalPages: res.totalPages,
                        totalInvitations: state.totalInvitations,
                        offset: res.offset,
                        currentPage: res.currentPage,
                        fetching: false,
                        openConfirm: state.openConfirm,
                        currentInvitationId: state.currentInvitationId,
                        serverError: state.serverError
                      };
            case "OpenConfirm" :
                return {
                        isShowMoreExpanded: state.isShowMoreExpanded,
                        invitations: state.invitations,
                        totalPages: state.totalPages,
                        totalInvitations: state.totalInvitations,
                        offset: state.offset,
                        currentPage: state.currentPage,
                        fetching: state.fetching,
                        openConfirm: action._0,
                        currentInvitationId: Caml_option.some(action._1),
                        serverError: state.serverError
                      };
            case "SetPageNo" :
                return {
                        isShowMoreExpanded: state.isShowMoreExpanded,
                        invitations: state.invitations,
                        totalPages: state.totalPages,
                        totalInvitations: state.totalInvitations,
                        offset: state.offset,
                        currentPage: action._0,
                        fetching: state.fetching,
                        openConfirm: state.openConfirm,
                        currentInvitationId: state.currentInvitationId,
                        serverError: state.serverError
                      };
            case "SetServerError" :
                return {
                        isShowMoreExpanded: state.isShowMoreExpanded,
                        invitations: state.invitations,
                        totalPages: state.totalPages,
                        totalInvitations: state.totalInvitations,
                        offset: state.offset,
                        currentPage: state.currentPage,
                        fetching: state.fetching,
                        openConfirm: state.openConfirm,
                        currentInvitationId: state.currentInvitationId,
                        serverError: action._0
                      };
            
          }
        }), {
        isShowMoreExpanded: false,
        invitations: props.invitations,
        totalPages: props.totalPages,
        totalInvitations: props.totalInvitations,
        offset: props.offset,
        currentPage: props.currentPage,
        fetching: false,
        openConfirm: undefined,
        currentInvitationId: undefined,
        serverError: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var updateInvitation = function (invitationId, status) {
    $$Promise.wait(Api.updateInvitation(invitationId, status), (function (x) {
            if (x.TAG !== "Ok") {
              return SentryLogger.error1({
                          rootModule: "UserInvitations",
                          subModulePath: {
                            hd: "UserInvitations",
                            tl: /* [] */0
                          },
                          value: "make",
                          fullPath: "UserInvitations.UserInvitations.make"
                        }, "UpdateInvitation::Error", [
                          "Error",
                          x._0
                        ]);
            }
            Url.visit(Routes_User.Personal.Dashboard.invitations);
            dispatch("ResetConfirm");
          }));
  };
  var acceptConfirm = function () {
    var id = state.currentInvitationId;
    if (id === undefined) {
      return dispatch("ResetConfirm");
    }
    var id$1 = Caml_option.valFromOption(id);
    var status = state.openConfirm;
    if (status !== undefined) {
      if (status === "Remove") {
        return $$Promise.wait(Api.deleteInvitation(id$1), (function (x) {
                      if (x.TAG === "Ok") {
                        return Url.visit(Routes_User.Personal.Dashboard.invitations);
                      }
                      SentryLogger.error1({
                            rootModule: "UserInvitations",
                            subModulePath: {
                              hd: "UserInvitations",
                              tl: /* [] */0
                            },
                            value: "make",
                            fullPath: "UserInvitations.UserInvitations.make"
                          }, "RemoveInvitation::Error", [
                            "Error",
                            x._0
                          ]);
                      dispatch({
                            TAG: "SetServerError",
                            _0: "Something went wrong"
                          });
                    }));
      } else {
        return updateInvitation(id$1, "rejected");
      }
    } else {
      return dispatch("ResetConfirm");
    }
  };
  var tmp;
  if (state.invitations.length !== 0) {
    var tmp$1;
    if (state.totalPages < 2) {
      var match$1 = state.totalInvitations;
      tmp$1 = match$1 !== 0 ? (
          match$1 !== 1 ? "Showing all" : "Showing 1"
        ) : "No invitations found";
    } else {
      tmp$1 = "Showing " + (String(state.offset + 1 | 0) + (" to " + (String(state.offset + state.invitations.length | 0) + (" of " + String(state.totalInvitations)))));
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("div", {
                                  children: "ID",
                                  className: Cx.cx([
                                        css.id,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: "Provider",
                                  className: Cx.cx([
                                        css.product,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: "Role",
                                  className: Cx.cx([
                                        css.user,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: "Invited At",
                                  className: Cx.cx([
                                        css.company,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: "Status",
                                  className: Cx.cx([
                                        css.status,
                                        css.tableHeaderCell
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: "Actions",
                                  className: Cx.cx([
                                        css.actions,
                                        css.tableHeaderCell
                                      ])
                                })
                          ],
                          className: css.invitationDataviewHeader
                        }),
                    JsxRuntime.jsx("div", {
                          children: Belt_Array.mapWithIndex(state.invitations, (function (idx, invitation) {
                                  var match = invitation.status;
                                  var tmp;
                                  switch (match) {
                                    case "Pending" :
                                        tmp = "Pending";
                                        break;
                                    case "Accepted" :
                                        tmp = "Accepted";
                                        break;
                                    case "Rejected" :
                                        tmp = "Rejected";
                                        break;
                                    
                                  }
                                  var match$1 = invitation.status;
                                  var tmp$1;
                                  switch (match$1) {
                                    case "Pending" :
                                        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsx(Button.make, {
                                                      size: "SM",
                                                      color: "Primary",
                                                      onClick: (function (param) {
                                                          updateInvitation(invitation.id, "accepted");
                                                        }),
                                                      children: "Accept"
                                                    }),
                                                JsxRuntime.jsx(Button.make, {
                                                      size: "SM",
                                                      color: "Red",
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "OpenConfirm",
                                                                _0: "Reject",
                                                                _1: invitation.id
                                                              });
                                                        }),
                                                      children: "Reject"
                                                    })
                                              ]
                                            });
                                        break;
                                    case "Accepted" :
                                        tmp$1 = JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Red",
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "OpenConfirm",
                                                        _0: "Remove",
                                                        _1: invitation.id
                                                      });
                                                }),
                                              children: "Leave Organization"
                                            });
                                        break;
                                    case "Rejected" :
                                        tmp$1 = null;
                                        break;
                                    
                                  }
                                  return JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx("div", {
                                                            children: "#" + ID.toString(invitation.id),
                                                            className: css.title
                                                          }),
                                                      className: css.id
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: invitation.providerUrl,
                                                                  targetBlank: true,
                                                                  className: css.title,
                                                                  children: $$String.capitalize_ascii(invitation.providerName)
                                                                }),
                                                            className: css.title
                                                          }),
                                                      className: css.product
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: $$String.capitalize_ascii(invitation.providerRole),
                                                      className: css.provider
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: Format(invitation.createdAt, "MM/dd/yyyy hh:mm aaaa"),
                                                      className: css.user
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx("b", {
                                                            children: tmp
                                                          }),
                                                      className: css.company
                                                    }),
                                                JsxRuntime.jsx("div", {
                                                      children: tmp$1,
                                                      className: css.actions
                                                    })
                                              ],
                                              className: css.dataRow
                                            }, String(idx));
                                })),
                          className: css.invitationDataviewBody
                        })
                  ],
                  className: css.invitationDataview
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    state.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            onPageClick: (function (pageNum) {
                                dispatch({
                                      TAG: "SetPageNo",
                                      _0: pageNum
                                    });
                                $$Promise.wait(Api.fetchUserInvitations(pageNum), (function (x) {
                                        if (x.TAG === "Ok") {
                                          return dispatch({
                                                      TAG: "UpdateInvitations",
                                                      _0: x._0
                                                    });
                                        } else {
                                          return SentryLogger.error1({
                                                      rootModule: "UserInvitations",
                                                      subModulePath: {
                                                        hd: "UserInvitations",
                                                        tl: /* [] */0
                                                      },
                                                      value: "make",
                                                      fullPath: "UserInvitations.UserInvitations.make"
                                                    }, "UserInvitations::FetchInvitations::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                        }
                                      }));
                              })
                          }) : null,
                    JsxRuntime.jsx("div", {
                          children: tmp$1,
                          className: css.paginationInfo
                        })
                  ],
                  className: css.paginationBar
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("span", {
                  children: JsxRuntime.jsx(IconInfo.make, {
                        title: "Info",
                        size: "MD",
                        color: "DarkOrange"
                      }),
                  className: css.icon
                }),
            "No Invitations Found"
          ],
          className: css.noRecordCard
        });
  }
  var x = state.openConfirm;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(TopNavbar.make, {
                      selectedCategory: "Invitations"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h1", {
                              children: "Invitations",
                              className: css.titleMain
                            }),
                        tmp
                      ],
                      className: css.contentWrapper
                    }),
                x !== undefined ? (
                    x === "Remove" ? JsxRuntime.jsx(ConfirmModal.make, {
                            mobile: mobile,
                            confirm: (function () {
                                acceptConfirm();
                              }),
                            close: (function () {
                                dispatch("ResetConfirm");
                              }),
                            message: "Are you sure? You want to remove from this provider.",
                            serverError: state.serverError
                          }) : JsxRuntime.jsx(ConfirmModal.make, {
                            mobile: mobile,
                            confirm: (function () {
                                acceptConfirm();
                              }),
                            close: (function () {
                                dispatch("ResetConfirm");
                              }),
                            message: "Are you sure? You want to reject this invitation.",
                            serverError: state.serverError
                          })
                  ) : null
              ],
              className: css.container
            });
}

var UserInvitations = {
  make: UserInvitations$UserInvitations
};

function $$default(jsProps) {
  return JsxRuntime.jsx(UserInvitations$UserInvitations, {
              mobile: jsProps.context.mobile,
              currentPage: jsProps.props.currentPage,
              offset: jsProps.props.offset,
              totalPages: jsProps.props.totalPages,
              totalInvitations: jsProps.props.totalInvitations,
              invitations: Belt_Array.map(jsProps.props.invitations, UserInvitationData.fromJs)
            });
}

export {
  css ,
  UserInvitations ,
  $$default as default,
}
/* css Not a pure module */
